import React, { FC, useState, useEffect, useMemo } from 'react'
import { Routes, Route } from 'react-router'
import { InfiniteList, ItemLink, ListWrapperProps } from '@/components/infinite_list/InfiniteList'
import { SideWithContent as L } from '@/components/layouts/CustomLayouts'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { GetRemindersQuery, SearchRemindersQuery } from '@/queries/reminders.queries'
import { SearchReminders_items, SearchReminders, SearchRemindersVariables } from '@/queries/_gen_/SearchReminders'
import { ReminderListItem } from './components/ReminderListItem'
import { order_by, reminder_bool_exp } from '@/queries/_gen_global'
import { useSortBy } from '@/hooks/searchHelperHooks'
import { usePathWithParams } from '@/hooks/usePathWithParams'
import { ReminderListFilter } from './components/ReminderListFilter'
import { createFilterBuilder } from '@/utils/search'
import { RemindersIndexView } from './RemindersIndexView'
import { ReminderDetailsView } from './ReminderDetailsView'
import { useSearchQuery, SearchQueryBag } from '@/hooks/useSearchQuery'
import { ReminderType } from '@/models/reminders'
import { ContactType } from '@/models/debtor'
import { ReminderSenderContextProvider } from '@/views/reminders/components/context/ReminderSenderContext'
import { RemindersSendRequestsSubProvider } from '@/views/reminders/components/context/RemindersSendRequestsSubContext'
import { ReminderStatsContextProvider } from '@/views/reminders/components/context/ReminderStatsContext'
import { SendRemindersButton } from './components/SendRemindersButton'
import styled from 'styled-components'
import { useRole } from '@/hooks/useRole'
import { valueToDateRange } from '@/components/filters/WhenFilter'
import { toEndOfDayBackendTimestamp, toStartOfDayBackendTimestamp } from '@/utils/date'
import { useApolloClient } from 'react-apollo'
import { GetReminders, GetRemindersVariables } from '@/queries/_gen_/GetReminders'
import { useEventSub } from '@/hooks/useEventSub'
import { DebtorUpdatedEvent } from '@/models/events'
import { withSpan } from '@/components/contexts/TraceContext'
import { useParams } from 'react-router-dom'
import { Checkbox, Row } from 'antd'
import { CompactButton } from '@/components/CompactButton'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { DownloadOutlined } from '@ant-design/icons'
import { logger } from '@/logger'
import { stages, getStage } from '@/models/stage'

const SendButton = styled(SendRemindersButton)`
  width: 100%;
`

const CompactFullButton = styled(CompactButton)`
  margin: 0 8px;
`

const ListHeaderWrapper = styled(Row)`
  padding: 12px 1em;
  border-bottom: 1px solid #f0f0f0;
`

const RemindersListItem: FC<{ reminder: SearchReminders_items }> = ({ reminder }) => (
  <ItemLink to={usePathWithParams(`/reminders/${reminder.id}`)}>
    <ReminderListItem reminder={reminder} />
  </ItemLink>
)

const remindersMissingExpr: reminder_bool_exp = {
  _or: [
    {
      type: { _eq: ReminderType.email },
      debtor: { _not: { debtor_contacts: { is_enabled: { _eq: true }, type: { _eq: ContactType.email } } } }
    },
    {
      _or: [{ type: { _eq: ReminderType.sms } }, { type: { _eq: ReminderType.phone } }],
      debtor: { _not: { debtor_contacts: { is_enabled: { _eq: true }, type: { _eq: ContactType.phone } } } }
    },
    {
      type: { _eq: ReminderType.mail },
      debtor: { _not: { debtor_contacts: { is_enabled: { _eq: true }, type: { _eq: ContactType.address } } } }
    }
  ]
}

const filterBuilder = createFilterBuilder<SearchRemindersVariables>({
  query: value => ({
    debtor: {
      _or: [
        { name: { _ilike: `%${value}%` } },
        { debtor_contacts: { value: { _ilike: `%${value}%` } } },
        { invoices: { document_number: { _eq: value } } }
      ]
    }
  }),
  type: value => ({ type: { _eq: value } }),
  status: value => ({ status: { _eq: value } }),
  has_contacts: value => {
    switch (value) {
      case 'yes':
        return { _not: remindersMissingExpr }
      case 'no':
        return remindersMissingExpr
      default:
        return {}
    }
  },
  send_reminders_request_id: value => ({
    reminder_send_requests: {
      reminders_send_request_id: { _eq: Number(value) }
    }
  }),
  import_file_id: value => ({
    import_file_id: { _eq: Number(value) }
  }),
  when: value => {
    if (value) {
      const range = valueToDateRange(value)
      if (range) {
        return {
          _and: [
            {
              created_at: {
                _gte: toStartOfDayBackendTimestamp(range.from)
              }
            },
            {
              created_at: {
                _lte: toEndOfDayBackendTimestamp(range.to)
              }
            }
          ]
        }
      }
    }
    return {}
  },
  stage: value => ({
    stage: { _eq: value }
  })
})

const RemindersList = (props: { searchBag: SearchQueryBag<SearchReminders_items, SearchRemindersVariables, SearchReminders> }) => {
  const selectedItemId = Number(useParams().id!)
  const currentClient = useCurrentClient()
  const { isManager } = useRole()
  const [checked, setChecked] = useState<number[]>([])
  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setIndeterminate(!!checked.length && checked.length !== props.searchBag.items.length)
    setCheckAll(!!checked.length && checked.length === props.searchBag.items.length)
  }, [checked])

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked ? props.searchBag.items.map(item => item.id) : [])
    setCheckAll(e.target.checked)
  }

  const downloadPdfs = () => {
    setLoading(true)

    fetch(`${__CONFIG__.backendUri}/reminder/gen/multi/pdf`, {
      method: 'POST',
      body: JSON.stringify({
        ids: checked
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
      .then(res => res.blob())
      .then(blob => {
        const newBlob = new Blob([blob], { type: 'application/x-zip' })

        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob)
        const link = document.createElement('a')
        link.href = data
        link.download = 'invorep-pdfs.zip'
        link.click()
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data)
        }, 100)
      })
      .catch(e => {
        logger.error('Failed to download reminder pdf zip file', e)
        throw e
      })
      .finally(() => setLoading(false))
  }

  const HeaderComponent: FC<Record<string, never>> = () => (
    <ListHeaderWrapper>
      <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}></Checkbox>

      <CompactFullButton
        loading={loading}
        type={'primary'}
        icon={<DownloadOutlined />}
        disabled={!checked?.length}
        onClick={() => downloadPdfs()}
      ></CompactFullButton>
    </ListHeaderWrapper>
  )

  const ListWrapper: FC<ListWrapperProps> = props => {
    const { innerComponent: InnerComponent } = props

    return (
      <Checkbox.Group
        style={{ width: '100%' }}
        value={checked}
        onChange={(checkedValues: CheckboxValueType[]) => {
          setChecked(checkedValues.map(cv => Number(cv)))
        }}
      >
        <InnerComponent />
      </Checkbox.Group>
    )
  }

  return (
    <>
      <InfiniteList
        searchBag={props.searchBag}
        key={`reminder-ilist-${currentClient.id}`}
        filtersComponent={ReminderListFilter}
        selectedItemId={selectedItemId}
        renderItem={reminder => <RemindersListItem reminder={reminder} />}
        headerComponent={HeaderComponent}
        listWraperComponent={ListWrapper}
        footerComponent={isManager ? () => <SendButton /> : undefined}
      />
    </>
  )
}

export const RemindersView = withSpan('RemindersView', undefined, () => {
  const currentClient = useCurrentClient()
  const stage = getStage(useParams().stage)
  const extraVariables: Partial<SearchRemindersVariables> = useMemo(
    () => ({
      where: {
        _or: [{ client_id: { _eq: currentClient.id } }, { client_id: { _is_null: true } }],
        ...(stage !== stages.all
          ? {
              stage: {
                _eq: `${stage}`
              }
            }
          : {})
      }
    }),
    [currentClient, stage]
  )

  const searchBag = useSearchQuery<SearchReminders_items, SearchRemindersVariables, SearchReminders>({
    query: SearchRemindersQuery,
    extraVariables,
    defaultSort: useSortBy('debtor.name', order_by.asc),
    buildFilterVariables: filterBuilder
  })

  const client = useApolloClient()

  // if debtor is updated, reload relevant reminder items
  useEventSub(DebtorUpdatedEvent, debtorId => {
    const toUpdateReminderIds = searchBag.data?.items.filter(item => item.debtor.id === debtorId).map(item => item.debtor.id)
    if (toUpdateReminderIds?.length) {
      console.log('updating reminders', toUpdateReminderIds)
      client
        .query<GetReminders, GetRemindersVariables>({
          query: GetRemindersQuery,
          variables: {
            reminder_ids: toUpdateReminderIds
          }
        })
        .finally(() => {
          // do nothing
        })
    }
  })

  return (
    <RemindersSendRequestsSubProvider>
      <ReminderSenderContextProvider>
        <ReminderStatsContextProvider where={searchBag.variables.where}>
          <L.Wrapper>
            <L.Side width={500}>
              <Routes>
                <Route path='/:id?/*' element={<RemindersList searchBag={searchBag} />} />
              </Routes>
            </L.Side>
            <L.Content>
              <Routes>
                <Route path='/:id/*' element={<ReminderDetailsView />} />
                <Route path='/' element={<RemindersIndexView />} />
              </Routes>
            </L.Content>
          </L.Wrapper>
        </ReminderStatsContextProvider>
      </ReminderSenderContextProvider>
    </RemindersSendRequestsSubProvider>
  )
})
